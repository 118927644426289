export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '',
    banner_img_root : '',
    resid : '',
    key_value : 'indiaveganhouse',
    secret_value : 'indiaveganhouse',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3055.627705970715!2d-105.27905159999999!3d40.016762799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bedf2eab12db3%3A0x278c7091a25fe178!2s1214%20Walnut%20St%2C%20Boulder%2C%20CO%2080302%2C%20USA!5e0!3m2!1sen!2sin!4v1667828375244!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"India Vegan House"
};
